import React from "react"
export const onClientEntry = () => {
	window.hbspt.forms.create({
		target: "#form-sales",
		region: "na1",
		portalId: "20211271",
		formId: "406c3176-90db-43e1-bc86-99d3aecdd99c",
	})

	// window.hbspt.forms.create({
	// 	target: "#form-rental",
	// 	region: "na1",
	// 	portalId: "20211271",
	// 	formId: "406c3176-90db-43e1-bc86-99d3aecdd99c",
	// })
}
